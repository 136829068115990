import React from 'react'
import { Wrap } from '../components/page/components'
import { graphql } from 'gatsby'
import TopicList from '../components/graphic-design/topic-list'
import { compose } from 'recompose'
import { withAuthorization } from '../components/session'
import SEO from '../components/SEO'
import AppHeader from '../components/global/app-header'
import AppBody from '../components/page/app-body'
import AppMenu from '../components/page/app-menu'
import DisclaimerCopy from '../components/disclaimer-copy'

const GraphicDesignBase = ({ data, authUser, location }) => {
  const { topics, subtopics } = data.contentfulCourse

  return (
    <>
      <Wrap>
        <SEO title="Career Guide" />
        <AppHeader
          title="Career Guide"
          authUser={authUser}
          searchDefault="career-guide"
          backUrl="/career-guide"
        />
        <AppBody>
          <DisclaimerCopy data={data} authUser={authUser} />
          <TopicList
            topics={topics}
            subtopics={subtopics}
            urlBase="/career-guide"
          />
        </AppBody>
        <AppMenu pathname={location.pathname} authUser={authUser} />
      </Wrap>
    </>
  )
}

export const query = graphql`
  query {
    contentfulCourse(slug: { eq: "career-guide" }) {
      topics {
        title
        slug
      }
      subtopics {
        title
        id
        slug
        releaseTiming
        publishDate
        code
        isActive
        topic {
          id
          slug
          title
        }
      }
    }
  }
`

const condition = authUser => !!authUser

const GraphicDesign = compose(withAuthorization(condition))(GraphicDesignBase)

export default GraphicDesign
