import React from 'react'
import { Box } from 'reflexbox'

const DisclaimerCopy = ({ authUser, data }) => {
  if (authUser && authUser.roles && authUser.roles.graduate) {
    return null
  }
  return (
    <Box mb={[3, 4]}>
      Want to see the Career Guide? Sign up for a paid Shillumni membership.
    </Box>
  )
}

export default DisclaimerCopy
